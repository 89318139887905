/* Policy Procedure Css Start Here */
.privacy_procedure {
	padding: 60px 0;
	background: #fafafa;
}
.privacy_procedure .heading_wrapper {
	text-align: center;
	padding-bottom: 50px;
}
.privacy_procedure .heading_wrapper h2 .colored {
	color: #f6a309;
}
.privacy_procedure .sidebar {
	border: 1px solid #ddd;
	border-radius: 5px;
}
.privacy_procedure .sidebar .nav-pills .nav-link {
	border-bottom: 1px solid #ddd;
	border-radius: 0;
	padding: 12px 20px;
	font-family: "poppins-regular";
}
.privacy_procedure .sidebar .title {
	padding: 15px 20px 5px;
}
.privacy_procedure .sidebar .nav-pills .nav-link.active {
	background: #f6a309;
	color: #fff !important;
}
.privacy_procedure .tab-content {
	height: 100%;
}
.privacy_procedure .tab-pane .topbar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;
}
.privacy_procedure .tab-pane .title h3 {
	position: relative;
	font-size: 25px;
}
.privacy_procedure .tab-pane .title h3 span {
	border-bottom: 3px solid #f6a309;
	padding-bottom: 5px;
}
.privacy_procedure .sidebar .nav.nav-pills {
	height: 750px;
	overflow: auto;
	display: block;
	padding-right: 5px;
}
.privacy_procedure .sidebar .nav.nav-pills::-webkit-scrollbar {
	width: 0.1em;
}
.privacy_procedure .sidebar .nav.nav-pills::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.privacy_procedure .sidebar .nav.nav-pills::-webkit-scrollbar-thumb {
	background-color: #f6a309;
	outline: 1px solid #f6a309;
}
.privacy_procedure .tab-pane .button-group .action-btns {
	margin: 0 2px;
}
.privacy_procedure .tab-pane .policy-content {
	padding-top: 10px;
}
.privacy_procedure .tab-pane .policy-content .name h4 {
	font-size: 20px;
	font-family: "poppins-medium";
}
.privacy_procedure .tab-pane .policy-content p {
	font-size: 14px;
	height: 220px;
	overflow-y: scroll;
	padding-right: 15px;
}
.privacy_procedure .tab-pane .policy-content p::-webkit-scrollbar {
	width: 0.4em;
}
.privacy_procedure .tab-pane .policy-content p::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px #f6a30940;
}
.privacy_procedure .tab-pane .policy-content p::-webkit-scrollbar-thumb {
	background-color: #f6a309;
}
/* Policy Procedure Css End Here */
