/* Subscription Sec Start Here */
.subscription {
	padding: 60px 0;
}
.subscription .heading-wrapper {
	padding-bottom: 20px;
	text-align: center;
}
.subscription .package-box {
	box-shadow: 0px 0px 10px #ddd;
	padding: 40px 40px;
	position: relative;
	border: 1px solid #ddd;
	margin-top: 120px;
}
.subscription .package-box .tag {
	background: #c93232;
	color: #fff;
	padding: 1px 22px;
	position: absolute;
	right: -70px;
	top: -10px;
	transform: rotate(45deg);
	clip-path: polygon(100% 0, 85% 48%, 100% 100%, 0 100%, 12% 50%, 0 0);
}
.subscription .first-package .tag {
	left: -70px;
	right: unset;
	transform: rotate(-40deg);
	top: -30px;
}
.subscription .package-box .tag p {
	font-size: 12px;
	line-height: 21px;
	margin: 0;
}
.subscription .package-box .header-bar {
	color: #000;
	padding: 20px 15px;
	max-width: 80%;
	margin: -100px auto 30px;
	text-align: center;
	background-image: linear-gradient(
		to left top,
		#f6a309,
		#f8ad2c,
		#fab743,
		#fdc157,
		#ffcb6b
	);
}
.subscription .package-box .header-bar h4 {
	font-size: 18px !important;
	line-height: 29px !important;
	min-height: 30px;
}
.subscription .package-box .header-bar h2 {
	margin: 13px 0;
}
.subscription .package-box .header-bar h5 {
	font-size: 17px;
	line-height: 17px;
}
.subscription .package-box p {
	font-size: 15px;
	line-height: 22px;
	font-family: "poppins-medium" !important;
}
.subscription .package-box ul.list {
	padding: 0;
	margin: 30px 0 30px;
	list-style: none;
	min-height: 175px;
}
.subscription .package-box ul.list li {
	font-family: "poppins-medium";
	font-size: 15px;
	margin: 8px 0;
}
.subscription .package-box ul.list li i {
	margin-right: 5px;
}
.subscription .package-box .button-group a {
	background: #000;
	color: #fff !important;
	font-family: "nunito-bold";
	padding: 13px 20px;
	width: 100%;
	display: block;
	text-align: center;
	text-decoration: none;
	border: 2px solid #f8ae2d;
	box-shadow: 0px 0px 0px 2px #000;
	transition: 0.6s;
	margin: 0 8px;
}
.subscription .package-box .button-group button {
	background: #000;
	color: #fff !important;
	font-family: "nunito-bold";
	padding: 13px 20px;
	width: 100%;
	display: block;
	text-align: center;
	text-decoration: none;
	border: 2px solid #f8ae2d;
	box-shadow: 0px 0px 0px 2px #000;
	transition: 0.6s;
	margin: 0 8px;
}
.subscription .package-box .button-group a:hover {
	box-shadow: 0px 0px 0px 2px #fff;
}
.subscription .package-box .button-group {
	display: flex;
}
.subscription .package-box .description {
	min-height: 177px;
}
/* Subscription Sec End Here */

/* Combo Price Sec Start Here */
.combo_sec {
	padding: 50px 0;
}
.combo_sec .combo_box {
	padding: 40px 40px;
	border-radius: 10px;
	background-image: linear-gradient(
		to left top,
		#f6a309,
		#f8ad2c,
		#fab743,
		#fdc157,
		#ffcb6b
	);
	position: relative;
}
.combo_sec .combo_box .tag {
	background: #c93232;
	color: #fff;
	padding: 1px 22px;
	position: absolute;
	left: -30px;
	top: 3px;
	transform: rotate(-45deg);
	clip-path: polygon(100% 0, 85% 48%, 100% 100%, 0 100%, 12% 50%, 0 0);
}
.combo_sec .combo_box .tag p {
	font-size: 13px;
	margin: 0;
	line-height: 21px;
}
.combo_sec .combo_box .content-wrapper p {
	max-width: 80%;
}
.combo_sec .combo_box .button-group a {
	background: #000;
	color: #fff !important;
	font-family: "nunito-bold";
	padding: 13px 20px;
	display: block;
	text-align: center;
	text-decoration: none;
	border: 2px solid #f8ae2d;
	box-shadow: 0px 0px 0px 2px #000;
	transition: 0.6s;
	margin: 0 8px;
	width: 45%;
}
.combo_sec .combo_box .button-group a:hover {
	box-shadow: 0px 0px 0px 2px #fff;
}
.combo_sec .combo_box .both-package {
	display: flex;
	align-items: center;
}
.combo_sec .combo_box .both-package .box {
	background: #ffffff8c;
	padding: 20px 10px;
	/* color: #fff; */
	text-align: center;
}
.combo_sec .combo_box .both-package .box h3 {
	font-size: 21px;
	font-family: "poppins-medium" !important;
	line-height: 30px;
}
.combo_sec .combo_box .both-package .box h4 {
	font-size: 20px;
}
.combo_sec .combo_box .both-package .plus {
	margin: 0 0px;
	font-size: 45px;
}
.combo_sec .combo_box .cobo-price-box {
	background: #ffffff8c;
	padding: 20px 10px;
	margin-top: 10px;
	text-align: center;
}
@media (max-width: 575px) {
	.subscription .heading-wrapper {
		padding-bottom: 20px;
	}
	.subscription .package-box {
		margin: 90px 0 0;
	}
	.subscription .first-package .tag {
		right: unset;
		transform: rotate(0deg);
		top: 16%;
		left: 0;
		right: 0;
		text-align: center;
	}
}
/* Combo Price Sec End Here */
