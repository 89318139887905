/* Dashboard Css Start Here */
.dashboard_sec {
	padding: 60px 0;
}
.dashboard_sec .heading-wrapper {
	text-align: center;
	padding-bottom: 60px;
}
.dashboard_sec .heading-wrapper h2 {
	color: #f6a309;
}
.dashboard_sec .heading-wrapper h4 {
	font-family: "poppins-medium";
	font-size: 28px;
	line-height: 38px;
}
.dashboard_sec .subscription-box {
	border: 1px solid #ddd;
	padding: 20px 10px;
	height: 170px;
}
.dashboard_sec .subscription-box {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin-bottom: 20px;
	border-radius: 8px;
	background: #fff;
	position: relative;
	overflow: hidden;
}
.dashboard_sec .subscription-box .img-fluid {
	width: 100px;
}
.dashboard_sec .subscription-box .inner-box .more-buttons {
	background: #ffcc6e;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: absolute;
	bottom: 0;
	width: 100%;
	left: 0;
	right: 0;
	margin: 0px auto;
	padding: 0 15px 10px;
}
.dashboard_sec .sale-box {
	padding: 0 5px;
	background: #ffcc6e;
}
.dashboard_sec .sale-box h4 {
	font-size: 22px !important;
	line-height: 32px !important;
}
.paid {
	background: #ffcc6e !important;
}
.dashboard_sec .unpaid .hover-box {
	position: absolute;
	bottom: 0;
	background: #000000e0;
	width: 100%;
	height: 100%;
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	transition: 0.6s;
	transform: translateY(150%);
}
.dashboard_sec .unpaid:hover .hover-box {
	transform: translateY(0);
}
.dashboard_sec .unpaid .hover-box .button-group a {
	color: #fff !important;
	font-size: 18px;
	line-height: 40px;
}
.dashboard_sec .subscription-box button {
	background: transparent;
	border: 0;
	padding: 0;
	box-shadow: none;
	outline: none;
}
.dashboard_sec .subscription-box .txt h4 {
	font-size: 18px;
	line-height: 28px;
	font-family: "nunito-bold";
}
.dashboard_sec .topBtns {
	margin-bottom: 30px;
}
.dashboard_sec .unpaid {
	filter: contrast(0.5);
}
.user-modal .modal-body .form-control {
	border-radius: 4px;
	height: 45px;
	border: 1px solid #ddd;
	font-family: "poppins-regular";
	font-size: 14px;
}
.user-modal .modal-body .form-control::placeholder {
	color: #b1b1b1;
}
.user-modal .modal-body select {
	color: #b1b1b1;
}
/* Dashboard Css End Here */

/* Analytical Dashbaord Css Start Here */
.stats-dashboard .sidebar .logo-box {
	padding: 20px 20px;
}
.stats-dashboard .tab-content .top-bar {
	border: 1px solid #ddd;
	padding: 10px 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;
}
.stats-dashboard .tab-content .top-bar .heading h3 {
	color: #f6a309;
	margin: 0;
}
.stats-dashboard .tab-content .top-bar .button-group {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.stats-dashboard .tab-content .top-bar .button-group h4 {
	font-size: 13px !important;
	line-height: 23px !important;
	text-align: center;
	font-family: "poppins-regular";
}
.stats-dashboard .tab-content .top-bar button {
	border: 0;
	background-color: transparent;
	outline: none;
	font-size: 15px;
	padding: 0 15px;
	box-shadow: none;
	cursor: pointer;
	font-family: "poppins-regular";
}
.stats-dashboard .tab-content .top-bar .add-user {
	color: green;
}
.stats-dashboard .tab-content .top-bar .remove-user {
	color: #dd0000;
}
.stats-dashboard .tab-content .CircularProgressbar {
	max-width: 60%;
	margin: 0px auto;
	display: table;
}
.stats-dashboard .tab-content .txt h5 {
	margin-bottom: 15px;
	font-family: "nunito-bold";
	display: flex;
	align-items: center;
	justify-content: center;
}
.stats-dashboard .score-table {
	margin-bottom: 35px;
}
.stats-dashboard .score-table .button-group {
	text-align: end;
	margin-bottom: 15px;
}
.stats-dashboard .score-table .table-responsive {
	border: 1px solid #ddd;
}
.stats-dashboard .score-table tbody {
	height: 210px;
	display: block;
}
.stats-dashboard .score-table th {
	border: 0;
	border-right: 1px solid #ddd;
	font-family: "poppins-regular";
}
.stats-dashboard .score-table td {
	border: 1px solid #ddd;
	border-left: 0;
	border-bottom: 0;
	font-size: 14px;
	color: #363636;
	font-family: "poppins-regular";
}
/* Analytical Dashbaord Css End Here */

/* Policies Page Css Start Here */
.policies_sec {
	padding: 80px 0;
}
.policies_sec .button-group {
	margin-bottom: 20px;
	text-align: end;
}
.policies_sec .table {
	border: 1px solid #ddd;
}
.policies_sec .table thead th {
	border: 0;
	border-bottom: 1px solid #ddd;
	border-right: 1px solid #ddd;
	padding: 14px 30px;
	font-family: "poppins-regular";
	text-align: center;
	background: #f6a3091c;
}
.policies_sec .table td {
	border: 0;
}
.policies_sec .table thead th:last-child {
	border-right: 0;
}
.policies_sec .table td {
	font-family: "poppins-regular";
	font-size: 14px;
	text-align: center;
	border-bottom: 1px solid #ddd;
	border-right: 1px solid #ddd;
	vertical-align: middle;
}
.policies_sec .table thead th:first-child {
	width: 10%;
}
.policies_sec .table td:first-child {
	width: 10%;
}
.policies_sec .table td:last-child {
	border-right: 0;
}
.policies_sec .table .edit button {
	border: 0;
	padding: 0;
	box-shadow: none;
	outline: none;
	color: #fff;
	width: 35px;
	height: 35px;
	border-radius: 5px;
	margin: 0 4px;
}
.policies_sec .table .edit .edit-btn {
	background: #f6a309;
}

.policies_sec .table .edit .delete {
	background: #e90000;
}
.policy-modal .modal-body {
	padding: 30px 20px 20px;
}
.policy-modal .modal-body select {
	border-radius: 0;
	height: 50px;
	font-family: "poppins-regular";
}
/* Policies Page Css End Here */

/* Students Table Css Start Here */
.students_sec {
	padding: 80px 0;
}
.students_sec .table {
	border: 1px solid #ddd;
}
.students_sec .table thead th {
	border: 0;
	border-bottom: 1px solid #ddd;
	border-right: 1px solid #ddd;
	padding: 14px 30px;
	font-family: "poppins-regular";
	text-align: center;
	background: #f6a3091c;
}
.students_sec .table td {
	border: 0;
}
.students_sec .table thead th:last-child {
	border-right: 0;
}
.students_sec .table td {
	font-family: "poppins-regular";
	font-size: 14px;
	text-align: center;
	border-bottom: 1px solid #ddd;
	border-right: 1px solid #ddd;
	vertical-align: middle;
}
.students_sec .table td:last-child {
	border-right: 0;
}
.policy-modal .modal-body select {
	border-radius: 0;
	height: 50px;
	font-family: "poppins-regular";
}
.students_sec {
	padding: 80px 0;
}
.students_sec .action button {
	border: 0;
	background: #f6a309;
	outline: none;
	box-shadow: none;
	color: #fff;
	margin: 2px 3px;
	width: 100%;
	padding: 10px 10px;
}
.view-course .modal-body .title {
	text-align: center;
}
.view-course .modal-body .table th {
	border: 1px solid #ddd;
	text-align: center;
	font-family: "poppins-semibold";
	color: #000;
}
.view-course .modal-body .table .sno {
	width: 20%;
}
.view-course .modal-body .table td {
	text-align: center;
	border: 1px solid #ddd;
	border-top: 0;
	font-family: "poppins-regular";
	font-size: 14px;
}
.view-course .modal-body .table td .delete-btn {
	border: 0;
	outline: none;
	box-shadow: none;
	background: #e90000;
	color: #fff;
	padding: 5px 10px;
	border-radius: 5px;
}
/* Students Table Css End Here */
